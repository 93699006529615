import {
  GET_CATEGORY_LIST,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  DELETE_CATEGORY,
} from "../../Const/ActionTypes";

import { setLoading, unsetLoading } from "./UIActions";
import { decrementCount, incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getCategory = (data) => {
  let filter = JSON.stringify({
    fields: {
      id: true,
      name: true,
      atc: true,
      fkChemicalIngredientId: true,
    },
    include: [
      {
        relation: "chemicalIngred",
        scope: {
          fields: {
            id: true,
            nameEn: true,
          },
        },
      },
    ],
    order: ["id DESC"],
  });

  return async (dispatch) => {
    await crudService
      .get(`/drug-categories?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: GET_CATEGORY_LIST,
          payload: res.data.map((element) => {
            return {
              ...element,
              value: element.id,
              label: element.name,
            };
          }),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const addCategory = (data, selectedIngredient) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/drug-categories`, data)
      .then((res) => {
        if (res.data.success.length > 0) {
          var tab = res.data.success.map((element) => {
            var object = element;
            var ingredient = selectedIngredient.filter(
              (ingrdient) => ingrdient.id === element.fkChemicalIngredientId
            );
            object.chemicalIngred = ingredient[0];
            return { ...object, value: element.id, label: element.name };
          });

          dispatch({
            type: ADD_CATEGORY,
            payload: tab,
          });

          dispatch(incrementCount());

          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "La création d'une nouvelle catégorie est effectuée avec succès",
          });
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
        if (res.data.failed.length > 0) {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: res.data?.failed?.[0],
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message === "Internal Server Error"
            ? "name duplicated"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const editCategory = (data, idCategory, selectedIngredient) => {
  return (dispatch) => {
    crudService
      .patch(`/drug-categories/` + idCategory, data)
      .then((res) => {
        var object = res.data;
        object.chemicalIngred = selectedIngredient;
        dispatch({
          type: EDIT_CATEGORY,
          payload: {...object , value: object.id, label: object.name},
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La modification  est effectuée avec succès",
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message === "Internal Server Error"
            ? "name duplicated"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      });
  };
};

export const deleteCategory = (id) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .remove(`/drug-categories/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_CATEGORY,
          payload: id,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La suppression a été effectuée avec succès",
        });

        dispatch(decrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
        window.scrollTo(0, 0);
      });
  };
};
